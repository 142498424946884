import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import * as config from '@/utils/config'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useProductsList() {
  // Use toast
  const toast = useToast()
  const showTable = ref(false)
  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: false },
    { key: 'region_id', label: 'Region', sortable: false },
    { key: 'status', sortable: false },
    { key: 'promo', label: 'promotion', sortable: false },
    { key: 'mobile', sortable: false },
    { key: 'email', sortable: false },
    { key: 'created_at', sortable: false },
    { key: 'updated_at', sortable: false },
  ]
  const perPage = ref(10)
  const totalOrders = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrders.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  const fetchOrders = (ctx, callback) => {
    store
      .dispatch('order/fetchOrders', {
        order: 'DESC',
        q: searchQuery.value,
        take: perPage.value,
        page: currentPage.value,
        status: statusFilter.value,
      })
      .then(response => {
        const products = response.body.data
        callback(products)
        totalOrders.value = response.body.meta.itemCount
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching products list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const timeout = ref(null)
  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      statusFilter,
    ],
    () => {
      showTable.value = true
           clearTimeout(timeout.value)

      timeout.value = setTimeout(() => {
        refetchData()
        showTable.value = false
      }, 600)
    },
  )
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusProduct = status => {
    if (status === 'draft') return 'info'
    if (status === 'pending') return 'warning'
    if (status === 'approved') return 'success'
    if (status === 'reject') return 'danger'
    return 'info'
  }

  const resolveTypeProduct = role => {
    if (role === 'public') return 'info'
    if (role === 'high_touch') return 'success'
    if (role === 'low_touch') return 'danger'
    return 'info'
  }

  const resolveUserStatusVariant = status => 'success'

  return {
    timeout,
    showTable,
    fetchOrders,
    tableColumns,
    perPage,
    currentPage,
    totalOrders,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    resolveTypeProduct,
    resolveUserStatusVariant,
    resolveStatusProduct,
    refetchData,
    // Extra Filters
    statusFilter,
  }
}
